import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"sm":"6","cols":"10"}},[_c('div',{staticClass:"mt-8 ml-7"},[_c('p',{class:!_vm.$vuetify.breakpoint.mobile ? 'display-1' : 'display-1 ',staticStyle:{"color":"#feb62d"}},[_c(VIcon,{class:!_vm.$vuetify.breakpoint.mobile ? 'mt-n2' : 'mt-n3 ',staticStyle:{"font-size":"65px"},attrs:{"color":"#FEB62D"}},[_vm._v("mdi-video-outline")]),_vm._v(" Videollamada ")],1)])]),_c(VCol,{attrs:{"sm":"6","cols":"2"}},[_c('div',{class:!_vm.$vuetify.breakpoint.mobile ? 'mt-12 text-end' : ' mt-9 ml-n10'},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.volver()}}},[_c(VIcon,{staticStyle:{"font-size":"50px"},attrs:{"color":"#FEB62D"}},[_vm._v("mdi-arrow-left")])],1)],1)])],1),_c(VRow,{staticClass:"d-flex justify-center align-center mt-16"},[_c(VCol,{attrs:{"xl":"6","lg":"6","md":"6","cols":"12"}},[_c(VCard,{staticStyle:{"background":"transparent"},attrs:{"elevation":"0"}},[_c(VRow,[_c(VCol,[_c('p',{staticClass:"title text-center",staticStyle:{"color":"#feb62d"}},[_vm._v("Selecciona una especialidad")]),_c(VSelect,{staticClass:"mx-auto",staticStyle:{"width":"50%"},attrs:{"background-color":"white","label":"General","items":_vm.items,"item-text":"name","item-value":"id","single-line":"","dense":"","outlined":""},model:{value:(_vm.itemSpeciality),callback:function ($$v) {_vm.itemSpeciality=$$v},expression:"itemSpeciality"}}),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"rounded-lg",staticStyle:{"color":"white"},attrs:{"width":"120","color":"#feb62d"},on:{"click":function($event){return _vm.videoCall()}}},[_vm._v("Llamar")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }