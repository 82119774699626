<template>
  <div
    id="Footer"
    data-w-id="957d9385-2e79-1f55-e3ff-38f8b5aa4f21"
    class="section footer wf-section"
  >
    <div class="containerhtml">
      <div class="footer-wrap">
        <div class="footer_cta">
          <h2 class="h2">Por un Municipio mas inteligente</h2>
          <div class="footer-text">
            Optimiza la gestión de los inspectores municipales. Mejora el
            Control Interno. Promueve una Fiscalización Municipal bien hecha.
            Recupera Recursos Perdidos. 
          </div>
          <div class="form-block w-form">
            <button class="button black form w-button" @click="changeDrawer">
              Solicitar información
            </button>
          </div>
        </div>
        <div class="line"></div>
        <div class="fotter_bottom-wrap">
          <div class="small margin-bottom-16">© 2023 Natural Phone</div>
          <div class="footer-flex">
            <a href="legal/terms-of-service.html" class="small right"
              >Términos del Servicio</a
            >
            <a href="legal/privacy-policy.html" class="small right"
              >Póliticas de privacidad</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    changeDrawer() {
      // changeDrawer
      this.$store.commit("setChangeDrawer");
    },
  },
};
</script>

<style>
</style>