import {endpoints} from './endpoints'
import request from '../request'

export function getFineByUID(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.getFineByUID,
			method: 'get',
			params: data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				resolve(error)
			});
	})
}

