import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height d-flex flex-column flex-grow-0",staticStyle:{"background-color":"#f2f2f2"}},[_c('div',{staticClass:"fill-height d-flex flex-column justify-space-between",attrs:{"id":"chat-messages-container"}},[_c('div',{staticClass:"d-flex flex-column align-end justify-end flex-grow-1 py-4",staticStyle:{"overflow-y":"scroll"},style:('height:' + _vm.chatContainerHeight + 'px'),attrs:{"id":"scroll-chats-container"}},_vm._l((Object.values( _vm.listMessage )),function(message,index){return _c(VContainer,{key:index,staticClass:"rounded-lg py-1",staticStyle:{"color":"#022d41"}},[_c('SingleMessage',{attrs:{"message":message}})],1)}),1),_c('div',{staticClass:"send-message-container d-flex flex-row align-center justify-center py-2"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-center px-4"},[_c(VBtn,{staticClass:"mt-1 mr-1",attrs:{"icon":""},on:{"click":_vm.onButtonClick}},[_c(VIcon,{attrs:{"size":"30","color":"#2250B3"}},[_vm._v("mdi-paperclip")])],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.onFileChanged}}),_c(VTextField,{staticStyle:{"width":"250px"},attrs:{"filled":"","rounded":"","dense":"","hide-details":"","placeholder":"Escribe un mensaje aquí"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13)return null;return _vm.insertDb()}},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c(VBtn,{staticClass:"ml-2",staticStyle:{"background":"#feb62d"},attrs:{"rounded":"","height":"40","width":"40px","icon":"","disabled":_vm.message === ''},on:{"click":function($event){return _vm.insertDb()}}},[_c(VIcon,{staticClass:"ml-1",attrs:{"outlined":"","color":"white"}},[_vm._v("mdi-send")])],1)],1)])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }