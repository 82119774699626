import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta'
import router from './router'
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueVideoPlayer);
Vue.use(VueMeta)
Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAVx5gJCh9MATmWOFIXOONY-YCc9R3yPlg',
    libraries: 'visualization',
  },
  installComponents: true
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
