<template>
  <div
    style="height: 100%"
  >
    <!-- <Header /> -->
    <Multa />
<!--    <Footer />-->
<!--    <Drawer />-->
  </div>
</template>

<script>
import Home from "./Multa.vue";
import Drawer from "../drawer/Drawer.vue";
// import Header from "../commons/Header.vue";
import Footer from "../commons/Footer.vue";
import Multa from "@/components/Multa/Multa";

export default {
  name: "Index",
  components: {
    Multa,
    Footer,
  },
  data() {
    return {
      key: 0,
    };
  },
  methods: {},
  mounted() {
    // const plugin = document.createElement("script");
    // plugin.setAttribute("src", "js/webflow.js");
    // plugin.setAttribute("type", "text/javascript");
    // document.head.appendChild(plugin);
    //
    // const plugin2 = document.createElement("script");
    // plugin2.setAttribute(
    //   "src",
    //   "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62f4510e73201624361551ab"
    // );
    // plugin2.setAttribute(
    //   "integrity",
    //   "sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0="
    // );
    // plugin2.setAttribute("crossorigin", "anonymous");
    // document.head.appendChild(plugin2);
  },
};
</script>

<style>
</style>