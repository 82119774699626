import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home/index.vue'
import Multa from "@/components/Multa";
// import PreguntasFrecuentes from '../components/PreguntasFrecuentes/index.vue'
// import Checkout from '@/components/checkout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/multa',
    name: 'Multa',
    component: Multa
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
