<template>
  <div style="background-image: repeating-linear-gradient(306deg, #65ff77, #befd9a);
  background-size: 100% 100%;
  height: 100%;
">
    <v-alert type="error" v-if="message"
             style="z-index: 5; position: absolute;  left: 0;  right: 0; margin-left: auto;  margin-right: auto; width: 300px; ">
      {{ message }}
    </v-alert>
    <div class="section-5 wf-section" style="  margin-top: 0;   padding-top: 20px;">
      <div class="div-block-18" v-if="!showDetail">
        <div class="div-block-12 _3">
          <img src="@/assets/images/Logo-oscuro.svg" loading="lazy" width="218" alt="" class="image-4">
        </div>
        <div class="text-block-9">Por favor escanear su carnet</div>
        <div class="div-block-19">
          <a href="#" data-w-id="8223d7fe-dda7-f1b3-cf6f-2d6444ee7321" class="button nav hide-on-mobile _2 _5 w-button"
             @click="scanQRCode">Presione
            para activar la camara</a>

        </div>
      </div>
      <div class="div-block-16">
        <a href="/" class="link-block-2 w-inline-block">
          <div class="text-block-6">X</div>
          <div class="text-block-6-copy"> | cerrar</div>
        </a>
      </div>
    </div>

    <div class="containerhtml-3 w-container">

      <div v-if="showCamera" class="scan-camera">
        <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit" :track="paintOutline">
          <div class="loading-indicator" v-if="loading">
            Cargando...
          </div>
          <div v-if="validationSuccess" class="validation-success">
            Redireccionando...
          </div>
          <div v-if="validationFailure" class="validation-failure">
          </div>
          <div v-if="validationPending" class="validation-pending">
            Validando..
          </div>
          <div v-if="!loading" class="instruction">
            <v-btn @click="switchCamera" class="switch-button mx-5" v-if="!hideSwitchCameraButton">
              <v-icon>mdi-camera-switch</v-icon>
            </v-btn>
            <v-btn @click="showCamera = false" class="close-button mx-5">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span class="mx-2">
          Por favor escanee código QR de su carnet
          </span>
          </div>
        </qrcode-stream>
      </div>
    </div>

    <!--    detail infraction-->
    <div class="section-4 wf-section" v-if="showDetail">
      <div class="containerhtml-3 w-container">
        <div data-w-id="d436453d-ac4d-38ab-d3ea-cd2df4d0f724" class="div-block-11"><img
            src="@/assets/images/Logo-oscuro.svg" loading="lazy" alt="" class="image-4">
          <div class="div-block-14">
            <video-player class="vjs-custom-skin"
                          ref="videoPlayer"
                          :options="playerOptions"
                          :playsinline="true"
                          @play="onPlayerPlay($event)"
                          @pause="onPlayerPause($event)"
                          @ended="onPlayerEnded($event)"
                          @loadeddata="onPlayerLoadeddata($event)"
                          @waiting="onPlayerWaiting($event)"
                          @playing="onPlayerPlaying($event)"
                          @timeupdate="onPlayerTimeupdate($event)"
                          @canplay="onPlayerCanplay($event)"
                          @canplaythrough="onPlayerCanplaythrough($event)"
                          @ready="playerReadied"
                          @statechanged="playerStateChanged($event)">
            </video-player>
            <h2 class="heading-5">Detalles de la infracción</h2>
            <div class="div-block-12 _2">
              <div class="text-block-2">Artículo</div>
              <div class="div-block-13">
                <div class="text-block-3">{{ details.infraction.type.article_number }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Ley</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.type.law_number }}<br></div>
              </div>
            </div>
            <div class="div-block-12-copy">
              <div class="text-block-2">Descripción</div>
              <div class="div-block-13-copy">
                <div class="text-block-5">{{
                    details.infraction.type.name
                  }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Fecha de la infracción</div>
              <div class="div-block-13">
                <div class="text-block-7">{{
                    formatInfractionDate
                  }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Fecha de citación al juzgado</div>
              <div class="div-block-13">
                <div class="text-block-8">{{ formatCitationDate }}<br></div>
              </div>
            </div>
            <h2 class="heading-6">Datos del dueño</h2>
            <div class="div-block-12 _2">
              <div class="text-block-2">{{
                  details.infraction.infraction_infractor.name
                }} {{ details.infraction.infraction_infractor.lastname }}

              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Rut</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ rutFormat }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Dirección</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.infraction_infractor.full_address }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Teléfono</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.infraction_infractor.phone }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Email</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.infraction_infractor.email }}<br></div>
              </div>
            </div>
            <h2 class="heading-6">Datos del Vehiculo</h2>
            <div class="div-block-12 _2">
              <div class="text-block-2">Patente</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.infraction_vehicle.ppu }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Marca</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.infraction_vehicle.brand }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Modelo</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.infraction_vehicle.model }}<br></div>
              </div>
            </div>
            <div class="div-block-12">
              <div class="text-block-2">Color</div>
              <div class="div-block-13">
                <div class="text-block-4">{{ details.infraction.infraction_vehicle.color }}<br></div>
              </div>
            </div>
            <div class="div-block-12-copy">
              <div class="text-block-2">Mapa</div>
              <!--              <img src="@/assets/images/mapa.svg" loading="lazy" alt="" class="image-6">-->
              <gmap-map
                  :center="center"
                  :zoom="zoom"
                  map-type-id="terrain"
                  style="width: 100%; height: 300px"
                  @click="center = $event.latLng"
                  :options="{
                  mapTypeControl: false,
                  streetViewControl: false,
                  fullscreenControl: false,
                  zoomControl: false,
                  gestureHandling: 'greedy',

                }"
              >
                <gmap-marker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center = m.position"
                ></gmap-marker>
              </gmap-map>
            </div>
<!--            <div class="div-block-12"-->
<!--                 style="margin-top: 20px; margin-bottom: 20px; display: flex; justify-content: center">-->
<!--              <v-btn-->
<!--                  color="primary"-->
<!--                  class="btn btn-primary w-button"-->
<!--                  @click="payInfraction"-->
<!--              >-->
<!--                Pagar multa-->
<!--              </v-btn>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {QrcodeStream} from 'vue-qrcode-reader'
import {format} from "rut.js";
import {videoPlayer} from "vue-video-player";
import {getFineByUID} from "@/components/helpers/sosdenuncias/api/complaints";

export default {

  name: "Multa",
  components: {
    QrcodeStream,
    videoPlayer,
  },
  data: () => ({
    message: '',
    showDetail: false,
    loading: false,
    isValid: undefined,
    result: null,
    showCamera: false,
    details: null,
    videoOptions: null,
    playerOptions: {
      height: '360',
      autoplay: true,
      muted: true,
      language: 'en',
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [{
        // type: "video/mp4",
        // mp4
        // src: "https://sosdenuncia-dev.s3.us-east-2.amazonaws.com/videoRecords/38e4e06e-be75-6614-6432-e145a6d0c8ea.webpm?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4GSHTRYIH2XTC24V%2F20221019%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20221019T195518Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Signature=c153b4a67bab95b2ceddbb8bf54cc98e504f1a593cade5673a3351b60093ad77",
        // webm
        // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
      }],
      poster: "https://surmon-china.github.io/vue-quill-editor/static/images/surmon-1.jpg",
    },
    center: {lat: -33.4372, lng: -70.6506},
    zoom: 15,
    complaint_uid: null,
    markers: [],
    camera: 'rear',
    hideSwitchCameraButton: false,
    noRearCamera: false,
    noFrontCamera: false
  }),

  computed: {

    validationPending() {
      return this.isValid === undefined
          && this.camera === 'off'
    },

    validationSuccess() {
      return this.isValid === true && this.camera === 'off'
    },

    validationFailure() {
      return this.isValid === false
    },
    formatInfractionDate() {
      const date = this.details.infraction.infraction_date.split('T')[0]
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatCitationDate() {
      const date = this.details.infraction.citation_date.split('T')[0]
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    rutFormat() {
      return format(this.details.infraction.infraction_infractor.id_code)
    }
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.complaint_uid = this.$route.query.id
    } else {
      this.$router.push({name: 'Home'})
    }
  },
  methods: {
    payInfraction() {
      console.log('pay')
    },
    switchCamera() {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
      }
    },

    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const {x, y} of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    scanQRCode() {
      this.showCamera = true
      this.camera = 'front'
    },
    async onInit(promise) {
      this.loading = true

      try {
        await promise
      } catch (error) {
        const triedFrontCamera = this.camera === 'front'
        const triedRearCamera = this.camera === 'rear'

        const cameraMissingError = error.name === 'OverconstrainedError'

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true
          this.camera = 'front'
          this.hideSwitchCameraButton = true
          this.message = 'No se encontró cámara trasera'
          setTimeout(() => {
            this.message = ''
          }, 3000)
          return;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
          this.camera = 'off';
          this.hideSwitchCameraButton = true;
          this.message = 'No se pudo acceder a la cámara'
          setTimeout(() => {
            this.message = ''
          }, 3000)
          this.showCamera = false;
          return;
        }

        this.message = 'No se pudo acceder a la cámara'
        setTimeout(() => {
          this.message = ''
        }, 3000)
        this.showCamera = false

        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async onDecode(content) {
      this.result = content
      this.turnCameraOff()
      // pretend it's taking really long
      await this.timeout(1000)
      this.isValid = content.startsWith('http')

      // some more delay, so users have time to read the message
      await this.timeout(1000)
      if (this.isValid) {
        this.getDetail()
      } else {
        this.showCamera = false
      }

    },
    async getDetail() {
      let run = this.result.split('RUN=')[1].split('&')[0].replace('-', '')
      let serial = this.result.split('serial=')[1].split('&')[0]

      try {
        await getFineByUID({
          complaint_uid: this.complaint_uid,
          id_code: run,
          verifier: serial
        }).then((data) => {

          this.details = data.complaint;
          this.playerOptions = {
            autoplay: true,
            controls: true,
            sources: [
              {
                src: data.complaint.videorecord.recording_link,
                type: 'video/webm'
              }
            ]
          };
          this.center = {
            lat: this.details.location.latitude,
            lng: this.details.location.longitude
          };
          this.markers = [
            {
              position: {
                lat: this.details.location.latitude,
                lng: this.details.location.longitude
              },
            }
          ];
          this.showCamera = false
          this.showDetail = true
        }).catch((error) => {
          console.log(error)
          this.message = 'No se pudo obtener el detalle de la infracción'
          setTimeout(() => {
            this.message = ''
          }, 3000)
          this.showCamera = false
        })
      } catch (e) {
        console.log(e)
        this.message = 'No se pudo obtener la información'
        setTimeout(() => {
          this.message = ''
        }, 3000)
        this.showCamera = false
      }
    },

    turnCameraOff() {
      this.camera = 'off'
    },

    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    onPlayerPlay(player) {
      // console.log('player play!', player)
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    onPlayerEnded(player) {
      // console.log('player ended!', player)
    },
    onPlayerLoadeddata(player) {
      // console.log('player Loadeddata!', player)
    },
    onPlayerWaiting(player) {
      // console.log('player Waiting!', player)
    },
    onPlayerPlaying(player) {
      // console.log('player Playing!', player)
    },
    onPlayerTimeupdate(player) {
      // console.log('player Timeupdate!', player.currentTime())
    },
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log('player current update state', playerCurrentState)
    },
    // player is ready
    playerReadied(player) {
      // seek to 10s
      console.log('example player 1 readied', player)
      player.currentTime(10)
      // console.log('example 01: the player is readied', player)
    }
  },
};
</script>

<style>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.instruction {
  position: absolute;
  /*background-color: rgba(255, 255, 255, .8);*/
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 10px;
  background-color: rgba(10, 10, 10, 0.75);
  color: white;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: rgba(10, 10, 10, 0.75);
  cursor: pointer;
}

.switch-button {
  position: absolute;
  top: 0;
  left: 0;
  margin: 5px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: rgba(10, 10, 10, 0.75);
  cursor: pointer;
}

.validation-success {
  color: green;
}

.validation-failure {
  color: red;
}

.scan-camera {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  /*bottom: 0;*/
  /*z-index: 1;*/
  width: 100%;

}
</style>